<template>
    <div class="wallet_save">
        <PayModal 
            :stage="modalStage"
            :errorMessage="errorMessage"
            :sucessMessage="'Успех!'"
            @enterPin="doWithdrawal" 
            @closeModal="closeModal()"             
            v-if="modalActive" 
            :pinCode="pinCode" 
         />
    </div>
</template>

<script>
import WalletList from '@/components/WalletList.vue'
import Button from '@/components/Button.vue'
import PayModal from '@/components/PayModal.vue'

export default {
    name: "WalletTopUp",
    components: {
        WalletList,
        Button,
        PayModal,
    },
    data () {
        return {
            modalStage: 'waiting',
            amount: '',
            card: '',
            pin: '',
            errorMessage: '',
            modalActive: true,
            topUpDisabled: false,
            topupStatusIntervalId: null,
        }
    },
    computed: {
        authToken () {
            return this.$store.state.authToken
        },
        transactionId() {
            return this.$route.query.order
        },
    },   
    mounted() {
        this.topupStatusIntervalId = setInterval(this.fetchTopUpStatus, 5000); // Check every 5 seconds
    },
    methods: {
        async fetchTopUpStatus() {

            const { data } = await this.$http.get(
                '/v2/wallet/top-up/status',
                {                                 
                    "params": { ...this.$route.query }, 
                    "headers": {
                        "Authorization": "Token " + this.authToken
                    }
                }
            );
            if (data.status === "complete") {
                this.modalStage = 'success'
            } else if (data.status === "failed") {
                this.modalStage = 'failed'
            }

        },
        async TopUp () {
            // this.modal_active = true;
            this.topUpDisabled = true;
            
            const { data } = await this.$http.post(
                '/v2/wallet/top-up',
                {
                    "amount": this.amount,
                    "currency_code": 'RUB'
                },
                {                    
                    "headers": {
                        "Authorization": "Token " + this.authToken
                    }
                 }
            ); 
            if (data.status === "error") {
                alert('TODO')
                // this.errorMessage = data.message
            } else {
                window.location = data.url;
            }
            
            // this.topUpDisabled = false;
        },
        closeModal () {
            // this.modal_active = false;
            window.location = '/'
        }
    }
}
</script>

<style>

</style>